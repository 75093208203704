import React from "react";

export default class Services extends React.Component {
  render() {
    return (
      <section id="fh5co-services" data-section="services">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-heading text-left">
              <h2 className=" left-border to-animate">Our Partners</h2>
              <div className="row">
                <div className="col-md-8 subtext to-animate">
                  <h3>
                    Cynergy Finance has partnerships in Qatar in the following
                    sectors:
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 fh5co-service to-animate">
              <i className="icon to-animate-2 icon-credit-card" />
              <h3>Banks and Financial companies</h3>
            </div>
            <div className="col-md-6 col-sm-6 fh5co-service to-animate">
              <i className="icon to-animate-2 icon-layers2" />
              <h3>Money Exchange companies</h3>
            </div>

            <div className="clearfix visible-sm-block" />

            <div className="col-md-6 col-sm-6 fh5co-service to-animate">
              <i className="icon to-animate-2 icon-settings" />
              <h3>Telecommunication providers</h3>
            </div>
            <div className="col-md-6 col-sm-6 fh5co-service to-animate">
              <i className="icon to-animate-2 icon-home" />
              <h3>Insurance companies</h3>
            </div>
            <div className="col-md-6 col-sm-6 fh5co-service to-animate">
              <i className="icon to-animate-2 icon-monitor" />
              <h3>Technology companies</h3>
            </div>
            <div className="col-md-6 col-sm-6 fh5co-service to-animate">
              <i className="icon to-animate-2 icon-anchor" />
              <h3>and many more...</h3>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
