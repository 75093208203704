import React from "react";

export default class Work extends React.Component {
  render() {
    return (
      <section id="fh5co-work" data-section="work">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-heading text-center">
              <h2 className="to-animate">What is Cynergy Finance</h2>
              <div className="row">
                <div className="col-md-8 col-md-offset-2 subtext to-animate">
                  <h3>
                    Cynergy Finance has revolutionized the way we use and think
                    about financial services on a global scale. With this
                    emerging technology swallowing up the Financial sector
                    rapidly, businesses such as Banking, Financial Services,
                    Money Exchange, Telecommunications and Insurance companies
                    are desperately searching to connect with quality Fintech
                    solution providers to automate and digitalize their
                    Financial Services. That’s where we come in.
                  </h3>
                </div>
                <div className="col-md-12">
                  <img
                    src="images/work_1.jpg"
                    alt="Image"
                    className="fh5co-project-item img-responsive img-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
