import React from "react";

import Axios from "axios";
import { ReCaptcha } from "react-recaptcha-v3";
import { loadReCaptcha } from "react-recaptcha-v3";

import { Form, Input, Spin, Icon } from "antd";

const { TextArea } = Input;
const FormItem = Form.Item;

const antIcon = (
  <Icon
    type="loading"
    style={{ fontSize: 24, marginLeft: -10, marginRight: 10, color: "white" }}
    spin
  />
);

class Contact extends React.Component {
  state = {
    loading: false,
    result: "",
    captcha: ""
  };

  componentDidMount() {
    loadReCaptcha("6Lciza4UAAAAAHiOkaE6uNkEohp8WZ-d0yWmi1jC");
  }

  verifyCaptcha(self, token) {
    self.setState({
      captcha: token
    });
  }

  handleSubmit(self, e) {
    e.preventDefault();

    self.props.form.validateFields((err, values) => {
      if (!err) {
        self.setState({ loading: true });
        let body = {
          recaptcha: self.state.captcha,
          name: values["Name"],
          email: values["Email"],
          phone: values["Phone"],
          message: values["Message"]
        };
        Axios.post(
          "https://jyrz232bs4.execute-api.ap-southeast-1.amazonaws.com/default/mailer",
          {
            origin: "cynergy-finance-contact-form",
            body: body
          }
        )
          .then(function (response) {
            if (response.data.errorMessage) {
              self.setState({ result: "error", loading: false });
            } else {
              self.props.form.resetFields();
              self.setState({ result: "success", loading: false });
            }
          })
          .catch(function () {
            self.setState({ result: "error", loading: false });
          });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <section id="fh5co-contact" data-section="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-heading text-center">
              <h2 className="to-animate">Get In Touch</h2>
              <div className="row">
                <div className="col-md-8 col-md-offset-2 subtext to-animate">
                  <h3>
                    For more information and enquiries, please contact us.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-bottom-padded-md">
            <div className="col-md-6 to-animate">
              <h3>Contact Info</h3>
              <ul className="fh5co-contact-info">
                <li className="fh5co-contact-address ">
                  <i className="icon-home" />
                  PO Box 3082 Doha - Qatar
                </li>
                <li>
                  <i className="icon-phone" /> <a href="tel:+97440376639" target="_blank">+974 40376639</a>
                </li>
                <li>
                  <i className="icon-envelope" />
                  info@cynergyfinance.com
                </li>
                <li>
                  <i className="icon-globe" />{" "}
                  <a href="http://www.cynergyfinance.com" target="_blank">
                    www.CynergyFinance.com
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-6 to-animate">
              <h3>Contact Form</h3>
              <form onSubmit={event => this.handleSubmit(this, event)}>
                <div class="form-group">
                  <label for="name" class="sr-only">
                    Name
                  </label>
                  <FormItem>
                    {getFieldDecorator("Name", {
                      rules: [{ required: true, message: "Name is required" }]
                    })(
                      <input
                        name="name"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        id="name"
                      />
                    )}
                  </FormItem>
                </div>
                <div class="form-group">
                  <label for="email" class="sr-only">
                    Email
                  </label>
                  <FormItem>
                    {getFieldDecorator("Email", {
                      rules: [{ required: true, message: "Email is required" }]
                    })(
                      <input
                        name="email"
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        id="email"
                      />
                    )}
                  </FormItem>
                </div>
                <div class="form-group">
                  <label for="phone" class="sr-only">
                    Phone
                  </label>
                  <FormItem>
                    {getFieldDecorator("Phone", {
                      rules: [{ required: true, message: "Phone is required" }]
                    })(
                      <input
                        name="phone"
                        type="text"
                        class="form-control"
                        placeholder="Phone"
                        id="phone"
                      />
                    )}
                  </FormItem>
                </div>
                <div class="form-group">
                  <label for="message" class="sr-only">
                    Message
                  </label>
                  <FormItem>
                    {getFieldDecorator("Message", {
                      rules: [
                        { required: true, message: "Message is required" }
                      ]
                    })(
                      <textarea
                        name="message"
                        id="message"
                        class="form-control"
                        cols="30"
                        rows="7"
                        placeholder="Message"
                      />
                    )}
                  </FormItem>
                </div>
                <ReCaptcha
                  sitekey="6Lciza4UAAAAAHiOkaE6uNkEohp8WZ-d0yWmi1jC"
                  verifyCallback={token => this.verifyCaptcha(this, token)}
                />
                <div class="form-group">
                  <button
                    disabled={this.state.loading}
                    class="btn btn-primary"
                    style={{
                      backgroundColor: this.state.loading
                        ? "lightgray"
                        : "#246df8",
                      cursor: this.state.loading ? "not-allowed" : "pointer"
                    }}
                  >
                    {this.state.loading && <Spin indicator={antIcon} />} Send
                  </button>
                  <br />
                  <br />
                  {this.state.result === "success" && (
                    <p className="text-success">
                      Your message has been sent. Thank you for contacting us.
                    </p>
                  )}
                  {this.state.result === "error" && (
                    <p className="text-danger">
                      Something went wrong while sending. Please try leter.
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section >
    );
  }
}

export default Form.create()(Contact);
