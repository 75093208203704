import React from "react";

export default class Testimonials extends React.Component {
  render() {
    return (
      <section id="fh5co-testimonials" data-section="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-heading text-center">
              <h2 className="to-animate">Who is Cynergy Finance</h2>
              <div className="row">
                <div className="col-md-8 col-md-offset-2 subtext to-animate">
                  <h3>
                    Cynergy Finance is a consolidated universal partnership
                    platform and your prime gateway to Qatar. We connect state
                    of the art Fintech solution providers from all over the
                    globe to leading business partners in Qatar. Cynergy Finance
                    will help you establish and build instant partnerships with
                    the most prestigious clients in Qatar’s growing and
                    lucrative market.
                  </h3>
                </div>
                <div className="col-md-12">
                  <img
                    src="images/work_2.jpg"
                    alt="Image"
                    className="fh5co-project-item img-responsive img-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
