import React from "react";

export default class Home extends React.Component {
  render() {
    return (
      <section
        id="fh5co-home"
        data-section="home"
        style={{backgroundImage: "url(images/full_image_2.jpg)"}}
        data-stellar-background-ratio="0.5"
      >
        <div className="gradient" />
        <div className="container">
          <div className="text-wrap">
            <div className="text-inner">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <h1 className="to-animate">Welcome To Cynergy Finance </h1>
                  <h2 className="to-animate">
                    Cynergy Finance has revolutionized the way we use and think
                    about financial services on a global scale.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slant" />
      </section>
    );
  }
}
