import React from "react";
import ReactDOM from "react-dom";

import Header from "./pages/header";
import Home from "./pages/home";
import Intro from "./pages/intro";
import Work from "./pages/work";
import Testimonials from "./pages/testimonials";
import About from "./pages/about";
import Services from "./pages/services";
import Contact from "./pages/contact";
import Footer from "./pages/footer";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.Fragment>
    <Header />
    <Home />
    <Intro />
    <Work />
    <Testimonials />
    <About />
    <Services />
    <Contact />
    <Footer />
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
