import React from "react";

export default class Intro extends React.Component {
  render() {
    return (
      <section id="fh5co-intro">
        <div className="container">
          <div className="row row-bottom-padded-lg">
            <div
              className="fh5co-block to-animate"
              style={{backgroundImage: "url(images/img_7.jpg)"}}
            >
              <div className="overlay-darker" />
              <div className="overlay" />
              <div className="fh5co-text">
                <i className="fh5co-intro-icon icon-bulb" />
                <h2>Financial services</h2>
                <p>
                  We revolutionized the way we use and think about financial
                  services on a global scale.
                </p>
              </div>
            </div>
            <div
              className="fh5co-block to-animate"
              style={{backgroundImage: "url(images/img_8.jpg)"}}
            >
              <div className="overlay-darker" />
              <div className="overlay" />
              <div className="fh5co-text">
                <i className="fh5co-intro-icon icon-wrench" />
                <h2>Fintech solutions</h2>
                <p>
                  Connect with quality Fintech solution providers to automate
                  and digitalize your Financial Services.
                </p>
              </div>
            </div>
            <div
              className="fh5co-block to-animate"
              style={{backgroundImage: "url(images/img_10.jpg)"}}
            >
              <div className="overlay-darker" />
              <div className="overlay" />
              <div className="fh5co-text">
                <i className="fh5co-intro-icon icon-rocket" />
                <h2>Universal partnership</h2>
                <p>
                  Cynergy Finance will help you establish and build instant
                  partnerships with the most prestigious clients in Qatar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
