import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="footer" role="contentinfo">
        <a href="#" className="gotop js-gotop">
          <i className="icon-arrow-up2" />
        </a>
        <div className="container">
          <div className="">
            <div className="col-md-12 text-center">
              <p>&copy; 2019 Cynergy Finance</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <ul className="social social-circle">
                <li>
                  <a href="#">
                    <i className="icon-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icon-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icon-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
