import React from "react";

export default class About extends React.Component {
  render() {
    return (
      <section id="fh5co-about" data-section="about">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-heading text-center">
              <h2 className="to-animate">Why Cynergy Finance</h2>
              <div className="row">
                <div className="col-md-8 col-md-offset-2 subtext to-animate">
                  <h3>
                    We realize that superior expert knowledge of the market is
                    critical to business success when it comes to integrating
                    fintech service providers and strategic partnerships with
                    organizations. Our clients trust us and our expert technical
                    and business teams with their knowledge of Qatar’s rising
                    market. We use tried and tested methods that are legally
                    compliant to understand the needs of our clients and connect
                    them with innovative Fintech solution providers.
                  </h3>
                </div>
                <div className="col-md-12">
                  <img
                    src="images/work_3.jpg"
                    alt="Image"
                    className="fh5co-project-item img-responsive img-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
